<template>
  <div class="social-login">
    <div class="social-heading">
      <p class="social-tittle">{{loginTxt}}</p>
    </div>
    <div class="social-icons-container">
      <div class="social-icon" v-if="isActiveFacebookLogin" >
        <img @click="facebookLogin" :src="fbIcon" alt />
      </div>
      <!-- <div class="social-icon">
        <div id="vLiveGLogin" data-click_listener></div>
      </div> -->
      <div class="social-icon">
        <img @click="googleSignIn" id="Google-SignIn" :src="gIcon" alt />
      </div>
      
      <!-- <div class="social-icon">
        <img id="Apple-signIn" :src="appleIcon" alt />
      </div>-->
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import { _googleId, _googleClientSecret } from "../../../../provider-config.js";
import Utility from "@/mixins/Utility.js";
import { mapGetters, mapActions, mapMutations } from "vuex";
import "../../../../../node_modules/node-snackbar/dist/snackbar.css"

export default {
  data() {
    return {
      fbIcon: require("@/assets/icons/facebook.svg"),
      appleIcon: require("@/assets/icons/Apple.svg"),
      gIcon: require("@/assets/icons/google.svg"),
      type: null,
      loginTxt: "",
      isActiveFacebookLogin:false,
      isActiveGoogleLogin:false,
    };
  },
  mounted() {
    // this.appleSignin();
    this.loginTxt = this.$t(window.PROVIDER_SETUP_CONFIG.welcome_Txt);
    this.isActiveFacebookLogin = this.appConfig.featureEnabled.isFacebookLoginEnabled;
    this.isActiveGoogleLogin = this.appConfig.featureEnabled.isGoogleLoginEnabled;
   
    let self = this;
      window.google.accounts.id.initialize({
          client_id: _googleId,
           scope: 'email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
          callback: self.handleCredentialResponse.bind(self)
        });

        window.google.accounts.id.renderButton(
            document.getElementById("vLiveGLogin"),
            { type:"icon", shape:"circle",theme:"outline",text:"signup_with", size:"large"}
          );

    // this.googleSignIn()
    eventBus.$on("formType", (value) => {
      this.type = value;
    });
  },

  computed: {
    ...mapGetters(["country", "profileid", "appConfig", "getPairingFlag"]),
  },

  created() {
    eventBus.$on("login-response-social-profile", (data) => {

      if(this.appConfig.featureEnabled.isMultipleProfileEnabled) {
          let currentLanguage = this.getCurrentLanguageUrlBase();


           this.listProfiles().then((profileResponse) => {
            if (profileResponse.data && profileResponse.data.totalcount > 1) {

              this.$router.push({name: "switchProfile", params: { lang: currentLanguage, renderCancel: false }, query:{"islogin": true}})
              } else {
                if(this.getPairingFlag) {
                    this.$router.push({ name: "loginWithPinView"});
                } else {
                  this.$router.push({path: this.$route.path });
                }
              

              }
           });
        }

    });

    if (this.appConfig.featureEnabled.isMultipleProfileEnabled) {
      eventBus.$on("subscriberDetails-set-success", (data) => {
        
        // if(!data.autologin) {

        //   this.listProfiles().then((profileResponse) => {
        //     if (profileResponse.data.reason) {
        //       // this.formError = this.$t(profileResponse.data.reason);
        //     } else {
  
        //       profileResponse.data.data.forEach((profile, index) => {
        //         if (profile.profileid === this.profileid) {
        //           localStorage.setItem("currentProfileId", profile.profileid);
        //           localStorage.setItem("subscriberProfileDetails", JSON.stringify(profile));
  
        //           this.setProfileid(profile.profileid);
        //         }
        //       });
        //       if (profileResponse.data.totalcount > 1) {
        //         //this.$router.push("/switchProfile");
				// 				let currentLanguage = this.getCurrentLanguageUrlBase();
      	// 					 this.$router.push({name: "switchProfile", params: { lang: currentLanguage }})
        //       } else {
        //         if(profileResponse && profileResponse.data && profileResponse.data.data) { 

        //           let profileData = profileResponse.data.data;
        //           localStorage.setItem("currentProfileId", profileData[0].profileid);
        //           localStorage.setItem("subscriberProfileDetails", JSON.stringify(profileData[0]));
    
        //           this.setProfileid(profileData[0].profileid);
        //         }
        //       }
        //     }
        //   });
          
        // }
      });
    }
    eventBus.$on("social-login-failed", (data) => {

      if(data.errorcode) {
        // snackbar.show({
        //   text: this.$t(data.reason),
        //   textColor: "#ffffff",
        //   pos: "bottom-center",
        //   actionTextColor: "#de2600",
        // });

        // showSnackBar(this.$t(data.reason));
      }
    });

    // if (!window.FB) {

    
    window.dispatchEvent(window.facebookLoaded);
    // }
  },
  methods: {
    ...mapActions(["listProfiles"]),

    ...mapMutations(["setProfileid"]),

    facebookLogin() {
      if(!this.isActiveFacebookLogin){
        return;
      }
      let self = this;
      /* eslint-disable */
      FB.login(
        function(response) {
          if (response.authResponse) {
            let payload = {
              app: "FB",
              type: self.type ? self.type : "lookup",
              params: {
                devicetype: "PC",
                fbtoken: response.authResponse.accessToken,
                deviceos: self.getDeviceOS(),
                country: self.country ? self.country.CountryCode : "",
              },
            };
            eventBus.$emit("subscriberLogin", payload);
          }
        },
        { scope: "public_profile,email" }
      );
    },
    googleSignIn() {
        const client = google.accounts.oauth2.initCodeClient({
          client_id: _googleId,
          scope: 'email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
          ux_mode: 'popup',
          callback: (response) => this.handleGoogleAuthResponse(response), // Use an arrow function here
        });
  
        client.requestCode();
      },
  
      handleGoogleAuthResponse(response) {
        const idToken = response.id_token;
        const xhr = new XMLHttpRequest();
        xhr.open('POST', 'https://www.googleapis.com/oauth2/v4/token', true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
  
        // Set up the request body with the correct parameters, including client_secret
        const requestBody = `code=${response.code}&grant_type=authorization_code&client_id=${_googleId}&client_secret=${_googleClientSecret}&redirect_uri=${window.location.origin}`;
        xhr.onload = () => {
          const tokenResponse = JSON.parse(xhr.responseText);
          const accessToken = tokenResponse.id_token;  
          const payload = {
            app: "Google",
            type: this.type ? this.type : "lookup",
            params: {
              devicetype: "PC",
              deviceos: this.getDeviceOS(),
              gtoken: accessToken,
              country: this.country ? this.country.CountryCode : "",
            },
          };
  
          // Emit an event to send the payload to the server
          eventBus.$emit("subscriberLogin", payload);
        };
  
        xhr.send(requestBody);
      },
    appleSignin() {
      /* eslint-disable */
      AppleID.auth.init({
        clientId: "com.mobiotics.web.vlive",
        scope: "name email",
        redirectURI: "https://vlivewebapp-5ee2b.firebaseapp.com/",
        state: "qvlmvpyqBmyNx0x7MvBJ3DG7obdbUZ",
        usePopup: true, //or false defaults to false
      });
      let element = document.getElementById("Apple-signIn");
      element.addEventListener("click", function() {
        /* eslint-disable */
        AppleID.auth.signIn().then(
          (response) => {
            if (response.authorization) {
              let payload = {
                app: "Apple",
                params: {
                  devicetype: "PC",
                  deviceos: self.getDeviceOS(),
                  appletoken: response.authorization.id_token,
                },
              };
              eventBus.$emit("subscriberLogin", payload);
            }
          },
          (err) => {
            console.log(err);
          }
        );
      });
    },
  },

  beforeDestroy() {
    eventBus.$off("login-response-social-profile");
    eventBus.$off("subscriberDetails-set-success");
  },

  mixins: [Utility],
};
</script>

<style lang="scss">
@import "@/sass/_variables.scss";
@import "./socialLogin.scss"
</style>
